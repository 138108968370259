import BaseService from '@src/services/BaseService'
import * as storeFile from "@state/store";

class ExportPresentationJobService extends BaseService {
    constructor() {
        super('export-presentation-jobs')
    }

    getUrl() {
        return `${process.env.VUE_APP_API_ENDPOINT}/${this.filterTenant ? `tenant/${storeFile.default.getters['auth/tenantId']}/`: ''}${this.urlRoute}`
    }
}

export default new ExportPresentationJobService()
