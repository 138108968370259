<script>
export default {
    data() {
        return {
            exportTypes: [
                {
                    value: 'link',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TYPES.link'),
                },
                {
                    value: 'ppt',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TYPES.ppt'),
                },
                {
                    value: 'simple_photos_zip',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TYPES.simple_photos_zip'),
                },
                {
                    value: 'named_photos_zip',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TYPES.named_photos_zip'),
                },
            ],
            exportOrders: [
                {
                    value: 'point_of_sale',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TITLES.pos_alias'),
                },
                {
                    value: 'user',
                    text: this.getI18n('USERS', 'TITLES.user'),
                },
                {
                    value: 'answered_at_date_time',
                    text: this.getI18n('EXPORT_PRESENTATION_JOBS', 'ORDERS.answered_at_date_time'),
                },
            ],
        }
    },
}
</script>
