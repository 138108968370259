<template>
    <form-modal
        ref="formModal"
        :in-editing="true"
        :title="isApprove ? i18nApprovePhoto : i18nRejectPhoto"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row v-if="isApprove" class="modal-body message">
                    {{ i18nApprovalConfirmationText }}
                </b-row>
                <b-row v-else class="modal-body message">
                    {{ i18nRejectConfirmationText }}
                    <b-col md="12" class="mt-3">
                        <b-form-group
                            :label="i18nReason"
                            class="required label-pdv"
                            label-for="reason"
                            :invalid-feedback="i18nInvalidReasonLength"
                        >
                            <b-form-textarea
                                id="reason"
                                v-model.trim="$v.modal.reason.$model"
                                class="input-pdv-blue"
                                rows="6"
                                max-rows="6"
                                autofocus
                                autocomplete="off"
                                :disabled="submitLoading"
                                :state="validateField('reason', 'modal')"
                                @input="clearResponseError('reason', 'modal')"
                                @blur="$v.modal.reason.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                v-if="isApprove"
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="i18nApprove"
                @onClick="handleOk"
            />
            <delete-button
                v-else
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="i18nReject"
                @onClick="handleOk"
            />
            <cancel-label-button
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
        </template>
    </form-modal>
</template>

<script>
import FilesService from '@/src/services/FilesService.js'

import { requiredIf, minLength } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import FormModal from '@/src/components/FormModal.vue'
import DeleteButton from '@/src/components/DeleteButton'
import SaveButton from '@/src/components/SaveButton'
import CancelLabelButton from '@/src/components/CancelLabelButton'

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DeleteButton,
        SaveButton,
        CancelLabelButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
    ],
    data() {
        return {
            i18nCommon,
            submitLoading: false,
            isApprove: false,
            modal: {
                id: 0,
                reason: '',
            }
        }
    },
    validations: {
        modal: {
            reason: {
                required: requiredIf(function () {
                    return !this.isApprove
                }),
                minLength: minLength(15),
            },
        },
    },
    computed: {
        i18nApprovePhoto() {
            return `${this.getI18n(i18nCommon, 'approve')} ${this.getI18n(i18nCommon, 'photo')}`
        },
        i18nApprovalConfirmationText() {
            return this.getI18n('RESPONSES', 'approval_confirmation_text')
        },
        i18nRejectPhoto() {
            return `${this.getI18n(i18nCommon, 'reject')} ${this.getI18n(i18nCommon, 'photo')}`
        },
        i18nRejectConfirmationText() {
            return this.getI18n('RESPONSES', 'reject_confirmation_text')
        },
        i18nInvalidReasonLength() {
            return this.getI18n('ERROR_CODES', 'invalid_reason_length')
        },
        i18nReason() {
            return this.getI18n(i18nCommon, 'reason')
        },
        i18nApprove() {
            return this.getI18n(i18nCommon, 'approve')
        },
        i18nReject() {
            return this.getI18n(i18nCommon, 'reject')
        },
    },
    methods: {
        showModal(id, approve, comment) {
            this.modal = {
                id: id,
                reason: comment,
            }
            this.isApprove = approve
            this.submitLoading = false
            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modal.$touch()
            if (!this.$v.modal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }

        },
        handleComments() {
            // Lógica para lidar com os comentários
            console.log("Comments button clicked");
        },
        async handleSubmit() {
            const status = this.isApprove ? 'approved' : 'denied';
            const comment = this.modal.reason
            FilesService.update(this.modal.id, {
                status,
                comment
            })
            .then((response) => {
                this.data = response.data.data
                this.totalItems = response.data.meta.total
                this.perPage = response.data.meta.per_page
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
                return err
            })
            this.$emit('refresh', this.modal.id, status, this.modal.reason)
            this.$refs.formModal.hide()
        }
    },
}
</script>
