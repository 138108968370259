<template>
    <form-modal
        ref="formModal"
        :title="i18nPhoto"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row class="modal-body message">
                    <b-col md="12">
                        <b-form-group
                            :label="i18nReason"
                            class="label-pdv"
                            label-for="reason"
                        >
                            <b-form-textarea
                                id="reason"
                                v-model.trim="reason"
                                class="input-pdv-blue"
                                autocomplete="off"
                                rows="6"
                                max-rows="6"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :title="i18nClose"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
    ],
    data() {
        return {
            i18nCommon,
            reason: '',
        }
    },
    computed: {
        i18nPhoto() {
            return this.getI18n(i18nCommon, 'photo')
        },
        i18nReason() {
            return this.getI18n(i18nCommon, 'reason')
        },
        i18nClose() {
            return this.getI18n(i18nCommon, 'close')
        },
    },
    methods: {
        showModal(reason) {
            this.reason = reason
            this.$refs.formModal.show()
        },
        handleCancel() {
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$refs.formModal.hide()
        },
    },
}
</script>
