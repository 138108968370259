<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-blue"
        href="javascript: void(0);"
        :disabled="busy || disabled"
        @click="$emit('onClick')"
    >
        <template v-if="busy">
            <b-spinner small></b-spinner>
        </template>
        <template v-else>
            {{ getI18n('DASHBOARDS.TYPES.comment_photo') }}
        </template>
    </button>
</template>

<script>
    export default {
        props: {
            busy: {
                type: Boolean,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
        },
    }
</script>

